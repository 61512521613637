import React from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
// import MuiDialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';

/*
const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: 50,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  onClose: (event: any) => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});
*/

interface Props {
  children: React.ReactNode;
  open: boolean;
  onClose: (event: any, reason?: 'backdropClick' | 'escapeKeyDown') => void;
}

export default function VideoDialog({ children, open, onClose }: Props) {
  return (
    <Dialog onClose={onClose} aria-labelledby="video-dialog-title" open={open} maxWidth="sm" fullWidth PaperProps={{ sx: { boxShadow: 'none', backgroundColor: 'transparent' } }}>
      <DialogContent sx={{ padding: 0 }}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: 0,
            paddingBottom: '56.25%',
            overflow: 'hidden',
            marginBottom: 50,
            '& iframe': {
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
            },
          }}
        >
          {children}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
