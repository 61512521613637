import { useState, useCallback } from 'react';

export function useDialog(initialOpen = false): [boolean, () => void, () => void] {
  const [open, setOpen] = useState(initialOpen);

  const handleOpen = useCallback((): void => {
    setOpen(true);
  }, []);

  const handleClose = useCallback((): void => {
    setOpen(false);
  }, []);

  return [open, handleOpen, handleClose];
}
