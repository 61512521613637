import React from 'react';
import { GetStaticProps } from 'next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Head from 'src/components/Head';
import Layout from 'src/components/Layout';
import LinkButton from 'src/components/LinkButton';
import VideoDialog from 'src/components/VideoDialog';
import { useDialog } from 'src/utils/useDialog';
import { useI18n } from 'i18n';
import { SITE_NAME } from 'src/constants';

export default function Index(): React.ReactElement {
  const { t } = useI18n();
  const [open, handleOpen, handleClose] = useDialog();

  return (
    <>
      <Head title={SITE_NAME} description={t('index-description')} />
      <Layout>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', height: 'calc(100vh - 56px)', position: 'relative' }}>
          <Typography variant="h2" component="h1">
            {t('catchphrase')}
          </Typography>
          <br />
          <Typography variant="h5" component="h2" color="secondary">
            {t('sub-catchphrase')}
          </Typography>
          <br />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <LinkButton href="/app/dashboard" color="secondary" size="large" variant="contained" sx={{ m: 2 }}>
              {t('try')}
            </LinkButton>
            <Button color="inherit" size="large" onClick={handleOpen}>
              {t('watch-video')}
            </Button>
          </div>
        </div>
      </Layout>
      <VideoDialog open={open} onClose={handleClose}>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/S3Ige3rRaJg"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </VideoDialog>
    </>
  );
}

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
  };
};
