import React from 'react';
import NextHead from 'next/head';
import { NextSeo } from 'next-seo';
import { useI18n } from 'i18n';
import { useRouter } from 'next/router';
import { Top } from 'src/Images';
import { HOST, SITE_NAME, TWITTER_ACCOUNT, OG_TITLE_SUFFIX } from 'src/constants';

interface Props {
  title: string;
  description: string;
}

export default function Head({ title, description }: Props): React.ReactElement {
  const { locale, asPath } = useRouter();
  const { t } = useI18n();
  const metaLocale = t('locale');
  const rootPath = asPath.split('?')[0] === '/';
  const path = rootPath ? '' : asPath.split('?')[0];
  const localePath = locale === 'ja' ? '' : `/${locale}`;

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={`${HOST}${localePath}${path}`}
        openGraph={{
          url: `${HOST}${localePath}${path}`,
          title: `${title}${rootPath ? '' : OG_TITLE_SUFFIX}`,
          type: 'article',
          description,
          images: [
            {
              url: `${HOST}${Top}`,
            },
          ],
          site_name: SITE_NAME,
          locale: metaLocale,
        }}
        twitter={{
          site: TWITTER_ACCOUNT,
          cardType: 'summary_large_image',
        }}
      />
      <NextHead>
        <meta key="twitter:app:country" name="twitter:app:country" content="JP" />
        <meta key="twitter:image" name="twitter:image" content={`${HOST}${Top}`} />
        <meta key="twitter:creator" name="twitter:creator" content={TWITTER_ACCOUNT} />
      </NextHead>
    </>
  );
}
